import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles
  from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function AuthoredBooksSection() {
  const classes = useStyles();
  return (
      <div>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Scriitor</h2>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
                <p><i>Traducerile din poveste</i> Ed. Vellant, 2010</p>
                <p><i>Copilarie, numele tău e Roșu</i> Ed. Vellant, 2023. (nominalizata la Premiile Uniunii Scriitorilor, 2024)</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              Versiunea scenică a romanului <i>20 de ani în Siberia</i> de Anița Nandriș-Cudla
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              Piesa de teatru <i>Negri de fericire</i>, care a fost publicată în Spania cu titlul: <i>Hartos de felicidad</i>, Ed. Invasoras, 2019 (fiind lansată la Salonul de carte de teatru de la Madrid, octombrie 2019)
            </GridItem>
          </GridContainer>
        </div>
      </div>
  );
}
